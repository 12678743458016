import CategoriesApiService from './CategoriesApiController';

class CategoriesController {
    static getAllCategories() {
        return CategoriesApiService.getAllCategories();
    }
    static getCategoryById(id: string | number) {
        return CategoriesApiService.getCategoryById(id);
    }
    static getSubCategory(id: string | number) {
        return CategoriesApiService.getSubCategory(id);
    }
    static getSearchCategory(id: string | number) {
        return CategoriesApiService.getSearchCategory(id);
    }

}

export default CategoriesController;