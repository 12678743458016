import axios from 'axios';
import api from '../../services/apiMain';

const ApiService = {
    signInUser: (credentials: any) => {
        return api.post('/api/user/log_in', credentials);
    },

    createUser: (credentials: any) => {
        return api.post('/api/user', credentials);
    },

    changePassword: (credentials: any) => {
        return api.patch('/api/user/password/change', credentials);
    },

    resetPassword: (credentials: any) => {
        return api.patch('/api/user/password/reset', credentials);
    },

    editUser: (id: string, updateddata: any) => {
        return api.patch('/api/user/' + id, updateddata)
    },

    deleteUser: (id: string | number) => {
        return api.delete('/api/user/' + id)
    },

    assignAdmin: (id: string, adminAR: object) => {
        return api.patch('/api/user/assign_admin/' + id, adminAR)
    },

    receiveOTP: (phone: object) => {
        return api.post('/api/user/code', phone)
    },

    verifyOTP: (phoneOTP: object) => {
        return api.post('/api/user/code/verify', phoneOTP)
    },

    getUser: (id: string) => {
        return api.get('/api/user/' + id)
    },

    getSearchUser: (id: string, page: number) => {
        return api.get('/api/search/user/' + id + "?page=" + page)
    },

    getAllUser: (page: number) => {
        return api.get('/api/user?page=' + page)
    },
};

export default ApiService;
