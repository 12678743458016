import axios from 'axios';
import api from '../../services/apiMain';

const headers = { "Content-Type": "multipart/form-data" }
const CompanyApiService = {
    companyLogin: (credential: Object) => {
        return api.post('/api/company/log_in', credential);
    },
    createCompany: (data: FormData) => {
        return api.post('/api/company', data, { headers });
    },
    getCompanyById: (id: string | number) => {
        return api.get('/api/company/' + id);
    },
    updateCompany: (id: string | number, data: FormData) => {
        return api.post('/api/company/' + id, data, { headers });
    },
    updateCompanyActivity: (id: string | number, data: Object) => {
        return api.patch('/api/company/' + id, data);
    },
    updateCompanyStatus: (id: string | number, data: Object) => {
        return api.patch('/api/company/status/' + id, data);
    },
    deleteCompany: (id: string | number) => {
        return api.delete('/api/company/' + id);
    },
    getCompanyUser: (id: string | number) => {
        return api.get('/api/company/' + id);
    },
    getAllCompanies: () => {
        return api.get('/api/company');
    },
    getSearchCompanies: (id: string | number) => {
        return api.get('/api/search/company/' + id);
    },
    getPendingCompanies: () => {
        return api.get('/api/company/request/pending');
    },
    getCompanyByCategory: (id: string | number) => {
        return api.get('/api/company/category/' + id);
    },
};

export default CompanyApiService;
