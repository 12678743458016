import CompanyApiService from './CompaniesApiController';
import ApiService from './CompaniesApiController';

class CompaniesController {
    static companyLogin(credential: Object) {
        return CompanyApiService.companyLogin(credential);
    }
    static createCompany(data: FormData) {
        return CompanyApiService.createCompany(data);
    }
    static getCompanyById(id: string | number) {
        return CompanyApiService.getCompanyById(id);
    }
    static updateCompany(id: string | number, data: FormData) {
        return CompanyApiService.updateCompany(id, data);
    }
    static updateCompanyActivity(id: string | number, data: FormData) {
        return CompanyApiService.updateCompanyActivity(id, data);
    }
    static deleteCompany(id: string | number) {
        return CompanyApiService.deleteCompany(id);
    }
    static getCompanyUser(id: string | number) {
        return CompanyApiService.getCompanyUser(id);
    }
    static updateCompanyStatus(id: string | number, data: Object) {
        return CompanyApiService.updateCompanyStatus(id, data);
    }
    static getAllCompanies() {
        return CompanyApiService.getAllCompanies();
    }
    static getSearchCompanies(id: string | number) {
        return CompanyApiService.getSearchCompanies(id);
    }
    static getPendingCompanies() {
        return CompanyApiService.getPendingCompanies();
    }
    static getCompanyByCategory(id: string | number) {
        return CompanyApiService.getCompanyByCategory(id)
    }
}

export default CompaniesController;
