import axios from 'axios';
import api from '../../services/apiMain';

const headers = { "Content-Type": "multipart/form-data" }
const CompaniesApiController = {
    getAllCategories: () => {
        return api.get('/api/category');
    },
    getCategoryById: (id: string | number) => {
        return api.get('/api/category/'+id);
    },
    getSubCategory: (id: string | number) => {
        return api.get('/api/sub_category/' + id);
    },
    getSearchCategory: (id: string | number) => {
        return api.get('/api/search/category/' + id);
    }
};

export default CompaniesApiController;