import ApiResponseModel from "../models/ApiResponseModel";

const handleApiResponse = (axiosResponse: any) => {
    try {
        if (axiosResponse.status === 200) {
            return new ApiResponseModel(true, axiosResponse.data, null);
        } else {
            throw new Error(axiosResponse.response);
        }
    } catch (error: any) {
        return new ApiResponseModel(false, null, { status: axiosResponse.status, message: error.message });
    }
};

export default handleApiResponse;