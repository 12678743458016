import axios from 'axios';
import api from '../../services/apiMain';

const headers = { "Content-Type": "multipart/form-data" }
const BillApiService = {
    getBillOrders: (id: string | number) => {
        return api.get('/api/bills/orders/' + id); //api/client/bills/orders/
    },
    getBillById: (id: string | number) => {
        return api.get('/api/bill/' + id);
    },
    getUserBills: (id: string | number, status: string) => {
        return api.get('/api/bills/user/' + id + "/" + status);
    }
};

export default BillApiService;