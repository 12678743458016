import { ChangeEvent } from 'react'
import { useState } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import UserController from '../../controllers/Users/UserController';
import handleApiResponse from '../../services/ApiResponse';
import CompaniesController from '../../controllers/Companies/CompaniesController';

function User() {
    const [loading, setLoading] = useState(false);
    const [incorrect, setIncorrect] = useState(false);
    const [userData, setUserData] = useState(
        {
            email: "georgessader@hotmail.com",
            password: "AAADDDaaa123$",
            fcm_token: "123456789",
            device: "android"
        });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserData({
            ...userData,
            [name]: value
        });
    };

    async function handleSignin() {
        setLoading(true)
        try {
            const response = await UserController.signInUser(userData);
            const apiResponse = handleApiResponse(response)
            if (apiResponse.success) {
                localStorage.setItem('token', response.data["token"])
                localStorage.setItem('id', response.data["uuid"])
                localStorage.setItem('admin', response.data["super_admin"])
                localStorage.removeItem("company_id")
                window.location.href = "/profile";
                console.log('User signed in successfully:', apiResponse.data);
            } else {
                console.error('Error signing in:', apiResponse.error);
                setIncorrect(true)
            }
        } catch (error) {
            console.error('Error signing in:', error);
            setIncorrect(true)
        }

        setLoading(false);
    }

    async function handleSigninCompany() {
        setLoading(true)
        try {
            const response = await CompaniesController.companyLogin(userData);
            const apiResponse = handleApiResponse(response)
            if (apiResponse.success) {
                localStorage.setItem('token', response.data["token"])
                localStorage.setItem('id', response.data["uuid"])
                localStorage.setItem('company_id', response.data["company_id"])
                localStorage.removeItem("admin")
                window.location.href = "/profile";
                console.log('User signed in successfully:', apiResponse.data);
            } else {
                console.error('Error signing in:', apiResponse.error);
                setIncorrect(true)
            }
        } catch (error) {
            console.error('Error signing in:', error);
            setIncorrect(true)
        }
        setLoading(false);
    }


    return (
        <div className='holder'>
            <Card className='boxcard'>
                <h2 style={{ textAlign: "center" }}>Login</h2>
                <Stack direction="row">
                    <Stack direction="column" flex={1}>
                        <p>Username</p>
                        <TextField
                            label="Username"
                            variant="outlined"
                            className='inpp'
                            value={userData.email}
                            name='email'
                            onChange={handleChange} />
                    </Stack>
                </Stack>
                <Stack direction="row">
                    <Stack direction="column" flex={1}>
                        <p>Password</p>
                        <TextField
                            type='password'
                            label="Password"
                            variant="outlined"
                            className='inpp'
                            value={userData.password}
                            name='password'
                            onChange={handleChange} />
                    </Stack>
                </Stack>
                <small><a className='smalllink' href="/createuser">Create Account</a></small><br />
                <small><a className='smalllink' href="/resetpassword">Reset password</a></small>
            </Card>
            {
                incorrect ? (
                    <small style={{ color: "red" }}>Incorrect username or password</small>) : (null)
            }
            {loading ? (
                <CircularProgress className='boxbutton' style={{
                    marginTop: "10px"
                }} />) : (
                <>
                    <Button variant="contained" className='boxbutton' onClick={handleSignin} disableElevation style={{
                        backgroundColor: "#2173FE",
                        marginTop: "10px"
                    }}>
                        Sign In
                    </Button>
                    <Button variant="contained" className='boxbutton' onClick={handleSigninCompany} disableElevation style={{
                        backgroundColor: "#2173FE",
                        marginTop: "10px",
                        marginRight: "20px"
                    }}>
                        Sign In as Company
                    </Button>
                </>)}

        </div>
    )
}

export default User