import React from 'react'
import Headers from './Headers'
import Routess from '../routes'

function AppMenu() {
  return (
    <div>
      <Headers />
      <Routess />
    </div>
  )
}

export default AppMenu