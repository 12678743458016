import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ItemsController from '../../controllers/Items/ItemsController';
import { Box, Grid, Card, CardActionArea, CardMedia, CardContent, Button, Stack, Skeleton, Chip, IconButton, Typography, CircularProgress } from '@mui/material';
// import './CardDetails.css';
import handleApiResponse from '../../services/ApiResponse';
import DoneIcon from '@mui/icons-material/Done';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HistoryIcon from '@mui/icons-material/History';
import Tooltip from '@mui/material/Tooltip';


interface ItemsPriceProps {
  item: {
    title: string;
    name: string;
    description: string;
    sold: boolean;
    original_price: number;
    sold_price: number;
    basic_color: Record<string, string>;
    colors: Array<{ id: number; color: string; image: string; price: number }>;
    size: string[];
    size_details: Array<{ id: number; image: string; price: number; size: string; }>;
    item_code: string;
    in_stock: boolean;
    image: string;
  };
  onImageUpdate: (updatedImage: string) => void;

}

interface itemsCard {
  card: Object
}


function extractItemDetails(
  user_id: string,
  company_id: string,
  item_ids: Array<number>,
  address: string,
  longitude: number,
  latitude: number,
  description: string,

) {
  return {
    user_id,
    company_id,
    item_ids,
    address,
    longitude,
    latitude,
    description,

  };
}

function CardDetails() {
  const [loading, setLoading] = useState(false);
  const [loadingbtn, setLoadingBtn] = useState(false);
  const [item, setItem] = useState<any[]>([]);
  //const [item1, setItem1] = useState<any>({});

  const [company, setCompany] = useState("");
  const [colors, setColors] = useState<any[]>([]);
  const [sizes, setSize] = useState<any[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [zoomed, setZoomed] = useState(false);
  const [zoomStyle, setZoomStyle] = useState({ transformOrigin: 'center center' });
  const [count, setCount] = useState(0);
  const [TotalColorPrice, setTotalColorPrice] = useState<number>(0);
  const [TotalSizePrice, setTotalSizePrice] = useState<number>(0);
  const [TotalPrice, setTotalPrice] = useState<number>(0);
  const [noData, setNoData] = React.useState<any>(false);

  const [itemToCard, setItemToCard] = useState<any>({});

  const [selectedChip, setSelectedChip] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("");
  const [colorstatus, setColor] = useState<string>("");

  const handleChipClick = (chipValue: string) => {
    setSelectedChip(selectedChip === chipValue ? null : chipValue);
  };

  const [selectedColor, setSelectedColor] = useState<string | null>(null);

  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);




  const handleColorClick = (color: string) => {
    setSelectedColor(selectedColor === color ? null : color);
  };

  const handleSizeClick = (size: string) => {
    setSelectedSize(selectedSize === size ? null : size);
  };

  const [quantity, setQuantity] = useState<number>(1);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };


  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };


  const [openDialog, setOpenDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handlePlaceOrderClick = () => {
    // Open the confirmation dialog
    setOpenConfirmationDialog(true);
  };

  const handlePlaceOrderConfirmed = () => {
    // Close the confirmation dialog
    setOpenConfirmationDialog(false);
    placeOrder();
  };

  const handlePlaceOrderCanceled = () => {
    // Close the confirmation dialog
    setOpenConfirmationDialog(false);
    // Update status only if the order placement was canceled
    setStatus("Order placement canceled");
  };

  // const getCountPrice = () => {
  //   let TotalColorPrice = 0;
  //   let TotalSizePrice = 0;

  //   if (item.colors && Array.isArray(item.colors)) {
  //     for (const colorPrice of item.colors) {
  //       if (colorPrice.color === selectedColor) {
  //         TotalColorPrice = colorPrice.price;
  //         //console.log("TotalColorPrice", TotalColorPrice);
  //         break;
  //       }
  //     }
  //   }

  //   if (item.size_details && Array.isArray(item.size_details)) {
  //     for (const sizePrice of item.size_details) {
  //       if (sizePrice.size === selectedSize) {
  //         TotalSizePrice = sizePrice.price;
  //         //console.log("TotalSizePrice", TotalSizePrice);
  //         break;
  //       }
  //     }
  //   }

  //   const TotalPrice = item.sold
  //     ? count * (item.sold_price + TotalColorPrice + TotalSizePrice)
  //     : count * (item.original_price + TotalColorPrice + TotalSizePrice);

  //   setTotalColorPrice(TotalColorPrice);
  //   setTotalSizePrice(TotalSizePrice);
  //   setTotalPrice(TotalPrice);

  //   //console.log("the quantity of items:",count)
  //   //console.log(`total price including size and color: ${TotalPrice}`);
  //   return TotalPrice;
  // };




  // const handleAddToCart = async () => {
  //   // const TotalPrice = getCountPrice();
  //   const extractedDetails = extractItemDetails(
  //     localStorage.getItem("id") || '',
  //     item.id,
  //     item.company_id,
  //     [],
  //     [],
  //     selectedSize || '',
  //     selectedSize || '',
  //     selectedColor || '',
  //     [],
  //     [],
  //     false,
  //     count,
  //     "test"
  //   );
  //   //make sure that data are correct or not empty
  //   console.log(extractedDetails)
  //   try {
  //     setLoadingBtn(true)
  //     const response = await ItemsController.addToCard(extractedDetails)
  //     console.log(response.headers)
  //     const apiResponse = handleApiResponse(response)
  //     if (apiResponse.success) {
  //       setLoading(false)
  //       setColor("green")
  //       setStatus("Item Added")
  //     }
  //     else {
  //       setLoading(false)
  //       setColor("red")
  //       setStatus("Failed to add")
  //     }
  //   } catch (error: any) {
  //     setLoading(false)
  //     console.log(error)
  //     setColor("red")
  //     setStatus(error.response.data.message)
  //   }
  //   setLoadingBtn(false)
  //   console.log(localStorage.getItem("token"))
  // };


  // useEffect(() => {
  //   getCountPrice();
  // }, [count, selectedColor, selectedSize]);




  const handleMouseMove = (event: React.MouseEvent<HTMLImageElement>) => {
    // Assert the type of event.target as HTMLImageElement
    const target = event.target as HTMLImageElement;

    const { left, top, width, height } = target.getBoundingClientRect();
    const x = (event.clientX - left) / width * 100;
    const y = (event.clientY - top) / height * 100;
    setZoomStyle({
      transformOrigin: `${x}% ${y}%`,
    });
  };

  // async function fetchItemColor()
  // {
  //   const response = ItemsController.getItemColor(colors)
  // }


  const handleZoomToggle = () => {
    setZoomed(!zoomed);
  };


  useEffect(() => {
    if (zoomed) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [zoomed]);


  async function fetchItemsCard() {
    setLoading(true)
    try {
      if (localStorage.getItem("user_id")) {
        let id = "" + localStorage.getItem("user_id");
        const itemResult = await ItemsController.getItemsCard(id);
        const apiResponse = handleApiResponse(itemResult)
        if (apiResponse.success) {
          let d: any = Object.entries(Object.entries(apiResponse.data)[2])[1][1]
          for (const dd in d) {
            setItem(apiResponse.data.card[dd].data)
            setCompany(dd)
            setLoading(false)
          }
        }
        else {
          console.log("error")
          setLoading(false)
        }
      }
    } catch (error) {
      console.error('Error fetching item details:', error);
    }
  }

  useEffect(() => {
    //console.log(item)
  }, [item])

  useEffect(() => {
    fetchItemsCard();
  }, []);







  async function handleDelete(cardId: string | number) {
    try {
      const response = await ItemsController.deleteItemCard(cardId)
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        //setColor("green")
        //setStatus("Item Deleted")
        // Remove the deleted item from the 'item' state
        setItem((prevItems) => prevItems.filter((item) => item.card_id !== cardId));
        window.location.reload()
      }
      else {
        //setColor("red")
        //setStatus("Failed!")
        window.location.reload()
      }
    }
    catch (error: any) {
      //setColor("red")
      setStatus("Failed!")
      //window.location.reload()
    }
  }

  async function placeOrder() {
    try {
      let itemids = []
      for (var i = 0; i < item.length; i++) {
        itemids.push(item[i].card_id)
      }

      const extractedDetails = extractItemDetails(
        localStorage.getItem("user_id") || '',
        company || '',
        itemids || [],
        "zahle",
        1,
        1,
        "description",

      );
      console.log(extractedDetails)
      const response = await ItemsController.placeOrder(extractedDetails)
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        setColor("green")
        setStatus("Order Placed")
        // window.location.reload()
      }
      else {
        setColor("red")
        setStatus("Failed!")
        // window.location.reload()
      }
    }
    catch (error: any) {
      console.error('Error placing order:', error);

      setColor("red")
      setStatus("Failed!")
      // window.location.reload()
    }
  }




  const handleDeleteClick = (cardId: React.SetStateAction<null>) => {
    setItemToDelete(cardId);
    setOpenDialog(true);
    // Reset the status when the delete dialog is opened
    setStatus("");
  };

  const handleDeleteConfirmed = () => {
    if (itemToDelete) {
      handleDelete(itemToDelete);
    }
    setOpenDialog(false);
  };


  const handleDeleteCanceled = () => {
    setItemToDelete(null);
    setOpenDialog(false);
    // Update status only if the order placement was canceled
    setStatus("Delete item canceled");
  };



  return (
    <div id="idetail" className={zoomed ? 'zoomed' : ''}>
      {loading ? (
        <Skeleton variant="rectangular" width={'80%'} height={200} style={{ margin: "auto", marginTop: "10px" }} />
      ) : (
        <>
          {!noData ? (
            <>
              <span>
                <Tooltip title="Orders" arrow>
                  <Link to="../../../orders/active" style={{ textDecoration: 'none' }}>
                    <IconButton
                    >
                      <HistoryIcon
                        style={{
                          margin: '8px',
                        }}
                      />
                    </IconButton>
                  </Link>
                </Tooltip>
              </span>
              <div>

              </div>

              <div>
                {
                  item.map((item: any) => (
                    <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                      <Grid item xs={12} md={6} sx={{ display: 'flex' }}>


                        <Card sx={{ flex: '1', display: 'flex', overflow: 'hidden' }}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              style={{
                                width: '60%',
                                margin: 'auto',
                                objectFit: 'contain', // to make the image cover the entire container
                                ...zoomStyle
                              }}
                              image={`https://api.kolshein.online/storage/${item.item_image}`}
                              alt={item.item_name}
                              className="zoom-image"
                              onMouseMove={handleMouseMove}
                            />

                          </CardActionArea>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                        <Card sx={{ flex: '1' }}>
                          <CardContent>

                            <Stack direction="row" spacing={1} justifyContent={'space-between'} alignItems="center">
                              <p>
                                <b>{item.item_name}</b>
                              </p>

                              <Stack direction="row" spacing={1} alignItems="center">
                                <Tooltip title="Edit" arrow>

                                  <IconButton onClick={() => { window.location.href = `../../../item_details_edit/${item.card_id}` }}>
                                    <ModeEditIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete" arrow>

                                  <IconButton onClick={() => handleDeleteClick(item.card_id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>

                              </Stack>
                            </Stack>


                            <p>{item.item_description}</p>
                            <div>Quantity : ${count} </div>
                            <div>
                              <span> Price :   </span>
                              {item.sold ? (
                                <>
                                  {/* <span> Before sold: </span> */}
                                  <del>${item.original_price}</del>
                                  {/* <span> After sold: </span>  */}
                                  <b style={{ color: 'red' }}>  ${item.sold_price}</b>
                                </>
                              ) : (
                                <b>${item.sold_price}</b>
                              )}
                            </div>


                            {item.color &&
                              (
                                <div style={{ display: "flex" }}>
                                  <span>Color: <Chip
                                    icon={<DoneIcon />}
                                    label={<span
                                      style={{
                                        color: item.color,
                                        fontSize: "40px"
                                      }}
                                    >
                                      ●
                                    </span>}
                                    style={{ margin: '1px' }}
                                  />
                                  </span>
                                </div>
                              )
                            }

                            <p>Choose size: <b>{item.size}</b></p>

                            {item.item_code && <p>Product Code: {item.item_code}</p>}
                          </CardContent>

                          <div style={{ width: 'fit-content', margin: 'auto', marginBottom: '10px' }}>
                            {loadingbtn ? (
                              <CircularProgress className='mt-4' />
                            ) : (
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                              </div>
                            )}
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  ))}
                <Dialog open={openDialog} onClose={handleDeleteCanceled}>
                  <DialogTitle>{`Delete ${(itemToDelete as any)?.item_name}?`}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete {(itemToDelete as any)?.item_name}?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDeleteCanceled}>Cancel</Button>
                    <Button onClick={handleDeleteConfirmed} autoFocus>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <Stack direction="row" spacing={1.0} justifyContent={'space-between'} alignItems="center">
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', marginTop: '15px' }}>
                  <Typography variant="subtitle1" style={{ marginRight: '5px' }}>Place Order:</Typography>
                  <Button variant="contained" onClick={handlePlaceOrderClick} style={{ marginTop: '5px', marginRight: '5px' }}>
                    Place Order
                  </Button>
                  <p style={{ color: colorstatus, marginTop: '15px', marginRight: '5px' }}>{status}</p>
                </div>
              </Stack>

              {/* Confirmation Dialog */}
              <Dialog open={openConfirmationDialog} onClose={handlePlaceOrderCanceled}>
                <DialogTitle>Confirm Order Placement</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to place the order?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handlePlaceOrderCanceled} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handlePlaceOrderConfirmed} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
              <div>No Items to show</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CardDetails;