import React, { useEffect, useState } from 'react'
import CategoriesController from '../../controllers/Categories/CategoriesController'
import CircularProgress from '@mui/material/CircularProgress';
import { Card, CardContent, Box, Grid, CardActionArea, CardMedia, Skeleton,Typography, styled, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import handleApiResponse from '../../services/ApiResponse';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function Categories() {
  const [sub_category, setSub_category] = React.useState<any>([]);
  const [noData, setNoData] = React.useState<any>(false);
  const { id } = useParams()
  const [loading, setLoading] = useState(false);



  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchSub_category() {
    let result;
    if (id) {
      result = await CategoriesController.getSubCategory(id);
      const apiResponse = handleApiResponse(result);
      if (apiResponse.success) {
        const newSubCategory = apiResponse.data;
        // Check if there are no child IDs
        if (newSubCategory.length === 0) {
          window.location.href = "../../../items/" + id;
          setNoData(true);
        } else {
          setNoData(false);
          setSub_category(newSubCategory);
        }
      } else {
        setNoData(true);
      }
    }
  }

  function goto(rout: string) {
    window.location.href = rout
  }

  useEffect(() => {
    fetchSub_category()
  }, [])

  useEffect(() => {
    //console.log("sub_category", sub_category)
    sub_category.forEach((element: {
      title: any;
      name_en: string;
      to: string;
      id: string;
    }) => {
      element.to = "../../../../category/" + element.id;
      element.title = element.name_en
    });
  }, [sub_category])



  return (
    <>
    {loading ? (
      <Skeleton variant="rectangular" width={'100%'} />
    ) : (
      <>
        {!noData ? (
      <Box sx={{ width: '100%', margin: 'auto', marginTop: '20px' }}>
        <Grid container rowSpacing={1} columnSpacing={1.0}>
          {sub_category.map((item: any) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={item.id}
              style={{
                width: "fit-content",
                margin: "auto"
              }}>
              <Card
                sx={{ maxWidth: 'auto' }}
                onClick={() => {
                  goto(item.to);

                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="auto"
                    image={`https://api.kolshein.online/storage/${item.image}`}
                  />
                  <CardContent>
                    <p
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <b>{localStorage.getItem("language")==="1" ? (item.name_en) : (item.name_ar)}</b>
                    </p>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        </Box>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
            <div>No Items to show</div>
          </div>
        )}
      </>
    )}
  </>
);
}


export default Categories