import AppMenu from './components/AppMenu';

function App() {
  return (
    <div className="App" style={{ backgroundColor: "#F4F5F9" }}>
      <AppMenu />
    </div>
  );
}

export default App;
