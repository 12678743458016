import { useEffect, useState } from 'react';
import BillsController from '../../controllers/Orders/OrdersController';
import handleApiResponse from '../../services/ApiResponse';
import { Box, Grid, Card, CardActionArea, CardContent, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';

interface Bill {
    id: string;
    data: Array<number | string>,
}

function BillsUsers() {
    const heads = ['Id', 'User Name', 'Company Name', 'Address', 'Status', 'Total', 'Action'];
    const [billBE, setBillBE] = useState<any>([]);
    const [noData, setNoData] = useState<any>(false);
    const { group } = useParams()

    //ra2m l fatoura, l total
    async function fetchBillUser() {
        const id = "" + localStorage.getItem("user_id")
        const result = await BillsController.getUserBills(id, group!)
        const apiResponse = handleApiResponse(result)
        if (apiResponse.success) {
            console.log(apiResponse)
            if (Array.isArray(apiResponse.data)) {
                if (billBE.length == 0)
                    setNoData(true)
            }
            else {
                setBillBE(apiResponse.data.data)
            }
        }
        else {
            setNoData(true)
        }
    }

    function goto(rout: string) {
        window.location.href = rout
    }

    useEffect(() => {
        fetchBillUser()
    }, [group])

    useEffect(() => {
        console.log(billBE)
    }, [billBE])

    return (
        <>
            <Box sx={{ width: '95%', margin: 'auto', marginTop: '20px' }}>
                {
                    group == "history" ? (
                        <span><a href='../../../orders/active' style={{
                            textDecoration: "none",
                        }}>Orders</a></span>
                    ) :
                        (
                            <span><a href='../../../orders/history' style={{
                                textDecoration: "none",
                            }}>History</a></span>
                        )
                }


                {
                    billBE.length > 0 ? (
                        <Grid style={{ marginTop: "10px" }} container rowSpacing={1} columnSpacing={1.0}>
                            {billBE.map((item: any) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={item.id}
                                    style={{
                                        width: "fit-content",
                                        margin: "auto"
                                    }}>
                                    <Card
                                        sx={{ maxWidth: 'auto' }}
                                        onClick={() => {
                                            goto("/ordersbill/" + item.id);
                                        }}
                                    >
                                        <CardActionArea>
                                            <CardContent>
                                                <p><b>Order Created:</b> {item.created_at}</p>
                                                <p><b>Description:</b> {item.description}</p>
                                                <p><b>Address:</b> {item.address}</p>
                                                <p><b>Company Name:</b> {item.company_name}</p>
                                                <p><b>Total Amount:</b> ${item.total_amount}</p>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        noData ? (<h3>No data found!</h3>) : (
                            <Grid style={{ marginTop: "10px" }} container rowSpacing={1} columnSpacing={1.0}>
                                {[1, 2, 3, 4, 5, 6].map((item: any) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={item.id}
                                        style={{
                                            width: "fit-content",
                                            margin: "auto"
                                        }}>
                                        <Skeleton variant="rectangular" width={'100%'} height={100} style={{ margin: "auto", marginTop: "10px" }} />
                                    </Grid>
                                ))}
                            </Grid>
                            // <Skeleton variant="rectangular" width={'100%'} height={100} style={{ margin: "auto", marginTop: "10px" }} />
                        )
                    )
                }
            </Box>
        </>
    );
}

export default BillsUsers;