import axios from 'axios';

const baseURL = 'https://api.kolshein.online';

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization':'Bearer ' + localStorage.getItem('token')
  },
});


export default api;
