import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import { FormControl, InputLabel, Select, SelectChangeEvent, Stack } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { SideBar } from '../components/SideBar';
import Signin from '../components/Users/Signin'
import CategoriesController from '../controllers/Categories/CategoriesController';
import handleApiResponse from '../services/ApiResponse';
import { useEffect, useState } from 'react';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import kolsheinImage from '../static/kolshein.png';
import kolsheinIconCart from '../static/kolsheinIconCart.png';
import renderSignUp from '../components/Users/Signin';
import LogoutIcon from '@mui/icons-material/Logout';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


// const items = [
//     {
//         title: "All Categories",
//         to: ""
//     },
//     {
//         title: "Home & Kitchen",
//         to: ""
//     },
//     {
//         title: "Electronics",
//         to: ""
//     },
//     {
//         title: "Fashion",
//         to: ""
//     },
//     {
//         title: "Health & Beauty",
//         to: ""
//     },
//     {
//         title: "Back To Stock",
//         to: ""
//     },
//     {
//         title: "New Arrivals",
//         to: ""
//     },
// ]

export default function Headers() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [showSignIn, setShowSignIn] = useState(true);

    // const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const showSide = () => {

    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    // Block of categories
    const [categories, setCategories] = useState<any>([])

    async function fetchCategories() {
        const response = await CategoriesController.getAllCategories()
        const apiResponse = handleApiResponse(response)
        if (apiResponse.success) {
            setCategories(apiResponse.data)
        }
    }

    function goto(rout: string) {
        window.location.href = rout
    }

    useEffect(() => {
        fetchCategories()
    }, [])

    useEffect(() => {
         console.log(categories)
        categories.forEach((element: {
            title: any;
            name_en: string;
            to: string;
            id: string;
        }) => {
            element.to = "../../../../category/" + element.id;
            element.title = element.name_en
        });
    }, [categories])

    // End of block

    const handleLogoClick = () => {
        // Redirect to the home page
        window.location.href = '/';
    };

    function logout() {
        localStorage.removeItem("token")
        localStorage.removeItem("user_id")
        window.location.href = "../../../"
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const [language, setLanguage] = React.useState(""+localStorage.getItem("language") || "1")

    const handleChangeLanguage = (event: SelectChangeEvent) => {
        setLanguage(event.target.value as string);
        localStorage.setItem("language",event.target.value);
        window.location.reload()
    };


    const mobileMenuId = 'primary-search-account-menu-mobile';



    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                {
                    localStorage.getItem("token") ? (
                        <div onClick={logout}>
                            <b>Logout</b>
                            <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit">
                                <LogoutIcon />
                            </IconButton>
                        </div>
                    ) : (
                        showSignIn ? <Signin /> : (renderSignUp as unknown as React.ReactNode)
                    )
                }

            </MenuItem>

            {
                localStorage.getItem("token") && (
                    <MenuItem>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"

                            onClick={() => { window.location.href = "../../../card" }}
                        >
                            <img
                                src={kolsheinIconCart}
                                alt="KolsheinIconCart Logo"
                                style={{
                                    width: '50px'
                                }}
                            />
                        </IconButton>
                        <p>Cart</p>
                    </MenuItem>
                )
            }

        </Menu>
    );


    const handleSignInSwitch = () => {
        setShowSignIn(!showSignIn);
    };

    const [searchText, setSearchText] = useState('');

    const handleSearch = () => {
        // Implement your search logic here
        console.log('Searching for:', searchText);
      };
    
      const handleKeyPress = (event: { key: string; }) => {
        if (event.key === 'Enter') {
          handleSearch();
        }
      };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{
                backgroundColor: "white",
                color: "black"
            }}>
                <Toolbar>
                    <SideBar items={categories} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                        onClick={handleLogoClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <img
                            src={kolsheinImage}
                            alt="Kolshein Logo"
                            style={{
                                width: '100px',
                            }}
                        />
                    </Typography>
                                <Search
                                style={{
                                    backgroundColor: 'rgb(240, 240, 240)',
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' }as any,
                                }}
                                >
                <IconButton onClick={handleSearch}  sx={{ marginLeft: { xs: 1, sm: 1 } }}>

                <SearchIconWrapper >
                    <SearchIcon />
                </SearchIconWrapper>
                </IconButton>

                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    sx={{ marginLeft: { xs: 1, sm: 2 } }}
                />
                </Search>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex', width: "300px", justifyContent: "space-evenly" } }}>

                        <Select
                            variant='standard'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={language}
                            label="Language"
                            onChange={handleChangeLanguage}
                        >
                            <MenuItem value={1}>English</MenuItem>
                            <MenuItem value={0}>Arabic</MenuItem>
                        </Select>
                        <div style={{ cursor: 'pointer' }}>
                            {
                                localStorage.getItem("token") ? (
                                    <div onClick={logout}>
                                        <b>Logout</b>
                                        <IconButton
                                            size="large"
                                            aria-label="show 17 new notifications"
                                            color="inherit">
                                            <LogoutIcon />
                                        </IconButton>
                                    </div>
                                ) : (
                                    showSignIn ? <Signin /> : (renderSignUp as unknown as React.ReactNode)
                                )
                            }
                        </div>
                        {
                            localStorage.getItem("token") && (
                                <div style={{
                                    cursor: "pointer",
                                    marginLeft: "-20px"
                                }}
                                    onClick={() => { window.location.href = "../../../card" }}
                                >
                                    <b>Cart</b>
                                    <IconButton
                                        size="large"
                                        edge="end"
                                        aria-label="account of current user"
                                        aria-controls={menuId}
                                        aria-haspopup="true"
                                        // onClick={handleProfileMenuOpen}
                                        color="inherit"
                                    >
                                        <img
                                            src={kolsheinIconCart}
                                            alt="KolsheinIconCart Logo"
                                            style={{
                                                width: '25px'
                                            }}
                                        />
                                    </IconButton>
                                </div>
                            )
                        }


                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
                <Stack
                    direction="row"
                    width={"60%"}
                    marginLeft={"40px"}
                    marginTop={"15px"}
                    marginBottom={"15px"}
                    alignItems={"center"}
                // justifyContent={"space-between"}
                >

                    <Box sx={{ minWidth: 210, display: { xs: 'none', md: 'block' } }}>
                        <FormControl fullWidth>
                            <InputLabel id="categorylabel"
                                style={{
                                    color: "black"
                                }}><CategoryOutlinedIcon /> All Categories</InputLabel>
                            <Select
                                style={{
                                    marginRight: "40px"
                                }}
                                variant='standard'
                                labelId="categorylabel"
                                id="demo-simple-select"
                                label="All Categories"
                            >
                                {categories.map((item: any) => (
                                    <MenuItem
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onClick={() => goto(item.to)}
                                        sx={{ display: { xs: 'none', md: 'block' } }}
                                    >
                                        {localStorage.getItem("language")==="1" ? (item.name_en) : (item.name_ar)}                                      </MenuItem>
                                ))}
                                {/* <MenuItem value={1}>English</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Box>


                    {
                        categories.map((item: any) => (
                            <Typography
                                style={{
                                    marginRight: "40px",
                                    cursor: "pointer"
                                }}
                                noWrap
                                component="p"
                                fontSize={"1"}
                                onClick={() => goto(item.to)}
                                sx={{ minWidth: '10%', display: { xs: 'none', md: 'block' } }}                            >
                                {localStorage.getItem("language")==="1" ? (item.name_en) : (item.name_ar)}                            </Typography>
                        ))
                    }
                </Stack>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
}