import { Route, Routes } from "react-router-dom";
import User from "./views/Users/User";
import ProtectedRoute from "./components/ProtectedRoutes";
import Profile from "./views/Users/Profile";
import Changepassword from "./views/Users/changepassword";
import ProtectedRouteAdmin from "./components/ProtectedRoutesAdmin";
import Categories from "./views/Categories/Categories";
import BillsUsers from "./views/Orders/Bills";
import OrdersBill from "./views/Orders/OrdersBill";
import Items from "./views/Items/Items";
import CardDetails from "./views/Items/card";
import ItemsDetails from "./views/Items/item-details";
import ItemsDetailsEdit from "./views/Items/item-details_edit";


function Routess() {
    return (
        <Routes>
            {/* <Route path="/" element={<Dashboard />} /> */}
            <Route path="/category" element={<Categories />} />
            <Route path="/card" element={<CardDetails />} />
            <Route path="/orders/:group" element={<BillsUsers />} />
            <Route path="/ordersbill/:id" element={<OrdersBill />} />
            <Route path="/category/:id" element={<Categories />} />
            <Route path="/items/:id" element={<Items />} />
            <Route path="/item_details/:id" element={<ItemsDetails />} />
            <Route path="/item_details_edit/:id" element={<ItemsDetailsEdit />} />
            <Route path="/category/:group/:id" element={<Categories />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/changepassword" element={<Changepassword />} />
            <Route path="/users" element={<User />} />
        </Routes>
    );
}

export default Routess;