import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import TextField from '@mui/material/TextField';
import FacebookIcon from '@mui/icons-material/Facebook';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, AlertColor, IconButton } from '@mui/material';
import { useState } from 'react';

import PasswordStrengthChecker from './PasswordCheck';

import CircularProgress from '@mui/material/CircularProgress';

import UserController from '../../controllers/Users/UserController';
import handleApiResponse from '../../services/ApiResponse';
import OtpInput from 'react-otp-input';
import { validateEmptyField } from '../validateField';

export default function MaxWidthDialog() {


    //block of dialog structures
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [switchSS, setSwitchSS] = useState(false)
    //end of block




    //block of user data
    const [userData, setUserData] = useState(
        {
            full_name: "",
            phone_number: "",
            email: "",
            password: "",
            confirm_password: "",
            language: 1
        }
    )
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: value
        }));
    };
    const [openOTP, setOpenOTP] = useState(false)
    const [otp, setOtp] = useState('');
    const [otpAlert, setOtpAlert] = useState({
        severity: "" as AlertColor,
        text: ""
    })
    //end of block


    //block of api call for user sign up
    async function receiveOTPUser() {
        try {
            const response = await UserController.receiveOTP(
                { "phone_number": userData.phone_number }
            )
            const apiResponse = handleApiResponse(response)
            if (apiResponse.success) {
                setLoading(false)
                console.log(apiResponse.data)
                setOpenOTP(true)
            }
            else {
                setLoading(false)
            }
        }
        catch (error: any) {
            setLoading(false)
            console.log(error)
            setOtpAlert({
                severity: "error",
                text: error.response.data.detail_en ?
                    error.response.data.detail_en :
                    error.response.data.details.otp_code.join(",")
            })
        }
    }



    async function createUser() {
        setLoading(true)
        try {
            const response = await UserController.createUser(userData)
            const apiResponse = handleApiResponse(response)
            if (apiResponse.success) {
                console.log(apiResponse.data)
                setLoading(false)
                setOpenOTP(false)
                setOtpAlert({
                    severity: "success",
                    text: "User Created Successfully"
                })
                setTimeout(function () {
                    setSwitchSS(false)
                }, 1000);
            } else {
                setLoading(false)
                setOpenOTP(false)
            }
        }
        catch (error: any) {
            setLoading(false)
            console.log(error.response)
            if (error.response.status === 400) {
                if (error.response.data.detail_en === "Phone number not verified yet.")
                    receiveOTPUser()
                else {
                    setOtpAlert({
                        severity: "error",
                        text: error.response.data.detail_en ?
                            error.response.data.detail_en :
                            "Unknown Error"
                    })
                }
            }
            else {
                setOpenOTP(false)
                setOtpAlert({
                    severity: "error",
                    text: error.response.data.detail_en ?
                        error.response.data.detail_en :
                        "Some of fields are required"
                })
            }
        }
    }


    async function verifyPhone() {
        setLoading(true)
        try {
            const response = await UserController.verifyOTP(
                {
                    "phone_number": userData.phone_number,
                    "otp_code": otp
                }
            )
            const apiResponse = handleApiResponse(response)
            if (apiResponse.success) {
                console.log(apiResponse.data)
                setLoading(false)
                setOtpAlert({
                    severity: "success",
                    text: "Verified!"
                })
                setOpenOTP(false)
                createUser()
            } else {
                setLoading(false)
                setOtpAlert({
                    severity: "error",
                    text: response.data.detail_en
                })
            }
        }
        catch (error: any) {
            setLoading(false)
            console.log(error)
            setOtpAlert({
                severity: "error",
                text: error.response.data.detail_en ?
                    error.response.data.detail_en :
                    error.response.data.details.otp_code.join(",")
            })
        }
    }
    //end of block

    //block of api call for user sign in
    async function signin() {
        try {
            setLoading(true)
            const response = await UserController.signInUser(
                {
                    "email": userData.email,
                    "password": userData.password,
                    "fcm_token": "123456789",
                    "device": "web"
                }
            )
            const apiResponse = handleApiResponse(response)
            if (apiResponse.success) {
                setLoading(false)
                console.log(apiResponse.data)
                setOtpAlert({
                    severity: "success",
                    text: "Success!"
                })
                console.log(apiResponse.data.token)
                localStorage.setItem("token", apiResponse.data.token)
                localStorage.setItem("user_id", apiResponse.data.uuid)
                handleClose()
                window.location.reload()
            }
            else {
                setLoading(false)
            }
        }
        catch (error: any) {
            setLoading(false)
            console.log(error)
            setOtpAlert({
                severity: "error",
                text: error.response.data.detail_en ?
                    error.response.data.detail_en :
                    "Unknown Error"
            })
        }
    }
    //end of block

    // function to handle the Sign In button click
    const handleSignInClick = () => {
        const emailError = validateEmptyField(userData.email, "Email");
        const passwordError = validateEmptyField(userData.password, "Password");

        // Update the state to trigger re-render with error messages
        setOtpAlert({
            severity: "error",
            text: emailError || passwordError || ""
        });

        // Check if there are any errors
        if (emailError || passwordError) {
            console.log("Validation errors:", emailError, passwordError);
            return;
        }

        // If no validation errors
        signin();
    };



    const renderSignIn = (
        <>
            <DialogTitle>Welcome back!</DialogTitle>
            <DialogContentText fontSize={20} color={"black"}><b>Sign in to your account</b></DialogContentText>
            <DialogContent>
                <DialogContentText fontSize={15}>
                    Don't have an account?
                    <span className='saddress' onClick={() => { setSwitchSS(true) }} style={{ cursor: 'pointer' }}> Sign Up</span>
                </DialogContentText>
                <TextField
                    className='inps'
                    label="Email"
                    variant="standard"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                //error={!!validateEmptyField(userData.email, "Email")}
                //helperText={validateEmptyField(userData.email, "Email")}
                />

                <TextField
                    className='inps mt-4'
                    label="Password"
                    variant="standard"
                    type='password'
                    name="password"
                    value={userData.password}
                    onChange={handleInputChange}
                // error prop: check if the text field is empty
                //error={!!validateEmptyField(userData.password, "Password")}
                // helperText prop: display the error message in red 
                //helperText={validateEmptyField(userData.password, "Password")}
                />

                <DialogContentText className='mt-3 mb-3'>
                    <a className='saddress' href='#'>Forgot your password?</a>
                </DialogContentText>

                {loading ? <CircularProgress
                    className='mt-4' /> :
                    <Button
                        className='mt-4'
                        sx=
                        {{
                            width: "100%",
                            '&:hover': {
                                backgroundColor: 'rgb(43, 129, 214)',
                                width: "100%",
                                color: 'white'
                            }
                        }}
                        onClick={handleSignInClick}
                    >
                        Sign in
                    </Button>
                }
                <DialogContentText className='mt-2 mb-3'>
                    -OR-
                </DialogContentText>

                <DialogContentText>
                    <FacebookIcon /><a className='saddress' href='#'>Login With Facebook</a>
                </DialogContentText>

                {
                    otpAlert.text === "" ?
                        null
                        :
                        <Alert
                            variant="outlined"
                            severity={otpAlert.severity}>
                            {otpAlert.text}
                        </Alert>
                }
            </DialogContent>
        </>
    )

    // function to convert the field value to a string
    const getStringValue = (value: string | number | null | undefined): string => {
        if (typeof value === 'string') {
            return value;
        } else if (typeof value === 'number') {
            return value.toString();
        } else {
            return '';
        }
    };


    const handleCreateAccount = () => {
        const errors: { [key: string]: string | null } = {};
        const fieldsToValidate = [
            { field: "full_name", label: "Full Name" },
            { field: "phone_number", label: "Phone Number" },
            { field: "email", label: "Email" },
            { field: "password", label: "Password" },
            { field: "confirm_password", label: "Confirm Password" },
        ];

        fieldsToValidate.forEach(({ field, label }) => {
            const fieldValue = userData[field as keyof typeof userData];
            const valueAsString = getStringValue(fieldValue);

            const error = validateEmptyField(valueAsString, label);
            if (error) {
                errors[field] = error;
            }
        });

        // Update the state to trigger re-render with error messages
        setOtpAlert({
            severity: "error",
            text: Object.values(errors).filter(Boolean).join(", ") || ""
        });

        // Check if there are any errors
        if (Object.values(errors).some(Boolean)) {
            console.log(errors, "\n");


            return;
        }

        // If no validation errors
        createUser();
    };



    const renderSignUp =
        openOTP ? (
            <>
                <DialogTitle><b>Verify Phone number</b></DialogTitle >
                <DialogContent>
                    <OtpInput
                        inputStyle={{
                            flex: "1"
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                    />
                    {loading ? <CircularProgress
                        className='mt-4' /> :
                        <Button
                            className='mt-4'
                            sx=
                            {{
                                width: "100%",
                                '&:hover': {
                                    backgroundColor: 'rgb(43, 129, 214)',
                                    width: "100%",
                                    color: 'white'
                                }
                            }}
                            onClick={verifyPhone}
                        >
                            Verify
                        </Button>}
                    {
                        otpAlert.text === "" ?
                            null
                            :
                            <Alert
                                variant="outlined"
                                severity={otpAlert.severity}>
                                {otpAlert.text}
                            </Alert>
                    }
                </DialogContent>
            </>
        ) :
            (
                <>
                    <DialogTitle><b>Create an account</b></DialogTitle >
                    <DialogContent>
                        <DialogContentText fontSize={15}>
                            Already have an account?
                            <span className='saddress' onClick={() => { setSwitchSS(false) }} style={{ cursor: 'pointer' }}> Sign In</span>
                        </DialogContentText>
                        <TextField
                            className='inps'
                            label="Full Name"
                            variant="standard"
                            name="full_name"
                            value={userData.full_name}
                            onChange={handleInputChange}
                        //error={!!validateEmptyField(userData.full_name, "Full Name")}
                        //helperText={validateEmptyField(userData.full_name, "Full Name")}
                        />

                        <TextField
                            className='inps mt-3 mb-3'
                            label="Phone Number"
                            variant="standard"
                            name="phone_number"
                            value={userData.phone_number}
                            onChange={handleInputChange}
                        //error={!!validateEmptyField(userData.phone_number, "Phone Number")}
                        //helperText={validateEmptyField(userData.phone_number, "Phone Number")}
                        />

                        <TextField
                            className='inps mt-3 mb-3'
                            label="Email"
                            variant="standard"
                            name="email"
                            value={userData.email}
                            onChange={handleInputChange}
                        //error={!!validateEmptyField(userData.email, "Email")}
                        //helperText={validateEmptyField(userData.email, "Email")}
                        />

                        <TextField
                            className='inps mt-3 mb-3'
                            label="Password"
                            variant="standard"
                            type='password'
                            name="password"
                            value={userData.password}
                            onChange={handleInputChange}
                        // error={!!validateEmptyField(userData.password, "Password")}
                        //helperText={validateEmptyField(userData.password, "Password")}
                        />
                        <PasswordStrengthChecker password={userData.password} />

                        <TextField
                            className='inps mt-3 mb-3'
                            label="Confirm Password"
                            type='password'
                            variant="standard"
                            name="confirm_password"
                            value={userData.confirm_password}
                            onChange={handleInputChange}
                        // error={!!validateEmptyField(userData.confirm_password, "Confirm Password")}
                        // helperText={validateEmptyField(userData.confirm_password, "Confirm Password")}
                        />
                        {loading ? <CircularProgress /> :
                            <Button
                                sx=
                                {{
                                    width: "100%",
                                    '&:hover': {
                                        backgroundColor: 'rgb(43, 129, 214)',
                                        width: "100%",
                                        color: 'white'
                                    }
                                }}
                                onClick={handleCreateAccount}
                            >
                                Create an account
                            </Button>}

                        {
                            otpAlert.text === "" ?
                                null
                                :
                                <Alert
                                    variant="outlined"
                                    severity={otpAlert.severity}>
                                    {otpAlert.text}
                                </Alert>
                        }
                    </DialogContent>
                </>
            )
    return (
        <React.Fragment>
            <div onClick={handleClickOpen}>
                <b>Sign In</b>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit">
                    <PermIdentityIcon />
                </IconButton>
            </div>

            <Dialog
                style={{
                    textAlign: "center",
                }}
                open={open}
                onClose={handleClose}
            >

                <DialogActions>
                    <span style={{ cursor: "pointer", fontSize: "25px" }} onClick={handleClose}><b>x</b></span>
                </DialogActions>
                {switchSS ? renderSignUp : renderSignIn}
            </Dialog>
        </React.Fragment>
    );
}