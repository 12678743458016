class ApiResponseModel {
    success: boolean;
    data: any;
    error: any;

    constructor(success: boolean, data: any, error: any) {
        this.success = success; 
        this.data = data;
        this.error = error;
    }
}

export default ApiResponseModel;