import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ItemsController from '../../controllers/Items/ItemsController';
import { Box, Grid, Card, CardActionArea, CardMedia, CardContent, Button, Stack, Skeleton, Chip, IconButton, Typography, CircularProgress } from '@mui/material';
import './ItemsDetails.css';
import handleApiResponse from '../../services/ApiResponse';
import DoneIcon from '@mui/icons-material/Done';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


interface ItemsPriceProps {
  item: {
    title: string;
    name: string;
    description: string;
    sold: boolean;
    original_price: number;
    sold_price: number;
    basic_color: Record<string, string>;
    colors: Array<{ id: number; color: string; image: string; price: number }>;
    size: string[];
    size_details: Array<{ id: number; image: string; price: number; size: string; }>;
    item_code: string;
    in_stock: boolean;
    image: string;
  };
}
function extractItemDetails(
  size: string | null,
  size_ar: string | null,
  color: string,
  quantity: number
) {
  return {
    size,
    size_ar,
    color,
    quantity,
  };
}


function ItemsDetailsEdit() {
  const [loading, setLoading] = useState(false);
  const [loadingbtn, setLoadingBtn] = useState(false);
  const [item, setItem] = useState<any>([]);
  const [item1, setItem1] = useState<any>({});
  const [colors, setColors] = useState<any[]>([]);
  const [sizes, setSize] = useState<any[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [zoomed, setZoomed] = useState(false);
  const [zoomStyle, setZoomStyle] = useState({ transformOrigin: 'center center' });
  const [count, setCount] = useState(1);
  const [TotalColorPrice, setTotalColorPrice] = useState<number>(0);
  const [TotalSizePrice, setTotalSizePrice] = useState<number>(0);
  const [TotalPrice, setTotalPrice] = useState<number>(0);
  const [noData, setNoData] = React.useState<any>(false);

  const [selectedChip, setSelectedChip] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("");
  const [colorstatus, setColor] = useState<string>("");

  const handleChipClick = (chipValue: string) => {
    setSelectedChip(selectedChip === chipValue ? null : chipValue);
  };

  const [selectedColor, setSelectedColor] = useState<string | null>(null);

  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleColorClick = (color: string) => {
    setSelectedColor(selectedColor === color ? null : color);
  };

  const handleSizeClick = (size: string) => {
    setSelectedSize(selectedSize === size ? null : size);
  };

  const [quantity, setQuantity] = useState<number>(1);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };


  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const getCountPrice = () => {
    let TotalColorPrice = 0;
    let TotalSizePrice = 0;

    if (item.colors && Array.isArray(item.colors)) {
      for (const colorPrice of item.colors) {
        if (colorPrice.color === selectedColor) {
          TotalColorPrice = colorPrice.price;
          //console.log("TotalColorPrice", TotalColorPrice);
          break;
        }
      }
    }

    if (item.size_details && Array.isArray(item.size_details)) {
      for (const sizePrice of item.size_details) {
        if (sizePrice.size === selectedSize) {
          TotalSizePrice = sizePrice.price;
          //console.log("TotalSizePrice", TotalSizePrice);
          break;
        }
      }
    }

    const TotalPrice = item.sold
      ? count * (item.sold_price + TotalColorPrice + TotalSizePrice)
      : count * (item.original_price + TotalColorPrice + TotalSizePrice);

    setTotalColorPrice(TotalColorPrice);
    setTotalSizePrice(TotalSizePrice);
    setTotalPrice(TotalPrice);

    //console.log("the quantity of items:",count)
    //console.log(`total price including size and color: ${TotalPrice}`);
    return TotalPrice;
  };




  const handleSaveItem = async () => {
    const extractedDetails = extractItemDetails(
      selectedSize || '',
      selectedSize || '',
      selectedColor || '',
      count,
    );
    //make sure that data are correct or not empty
    console.log(extractedDetails)
    try {
      setLoadingBtn(true)

      const response = await ItemsController.updateCardItem(id!, extractedDetails)
      console.log(response.headers)
      const apiResponse = handleApiResponse(response)

      if (apiResponse.success) {
        setLoading(false)
        setColor("green")
        setStatus("Item updated")
        // Assuming the updated item is returned in the response
        const updatedItem = apiResponse.data.item;
      }
      else {
        setLoading(false)
        setColor("red")
        setStatus("Failed to add")
      }
    } catch (error: any) {
      setLoading(false)
      console.log(error)
      setColor("red")
      setStatus(error.response?.data?.message || 'Failed to update');
    }
    setLoadingBtn(false)
    console.log(localStorage.getItem("token"))
  };


  useEffect(() => {
    getCountPrice();
  }, [count, selectedColor, selectedSize]);



  const handleMouseMove = (event: React.MouseEvent<HTMLImageElement>) => {
    // Assert the type of event.target as HTMLImageElement
    const target = event.target as HTMLImageElement;

    const { left, top, width, height } = target.getBoundingClientRect();
    const x = (event.clientX - left) / width * 100;
    const y = (event.clientY - top) / height * 100;
    setZoomStyle({
      transformOrigin: `${x}% ${y}%`,
    });
  };

  // async function fetchItemColor()
  // {
  //   const response = ItemsController.getItemColor(colors)
  // }


  const handleZoomToggle = () => {
    setZoomed(!zoomed);
  };


  useEffect(() => {
    if (zoomed) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [zoomed]);


  async function fetchItemDetails() {
    setLoading(true)
    try {
      if (id) {
        const itemResult0 = await ItemsController.getCardItem(id);
        const apiResponse0 = handleApiResponse(itemResult0)
        if (apiResponse0.success) {
          setItem(apiResponse0.data);
          setCount(apiResponse0.data.quantity);
          const itemResult = await ItemsController.getItemById(apiResponse0.data.item_id);
          const apiResponse = handleApiResponse(itemResult)
          if (apiResponse.success) {
            const colorsResult = await ItemsController.getItemColor(apiResponse0.data.item_id);
            const apiResponse2 = handleApiResponse(colorsResult)
            if (apiResponse2.success) {
              const sizeResult = await ItemsController.getItemSize(apiResponse0.data.item_id);
              const apiResponse3 = handleApiResponse(sizeResult)
              if (apiResponse3.success) {
                setItem1(itemResult.data);
                setColors(colorsResult.data);
                setSize(sizeResult.data);
                setLoading(false)
              }
              else {
                console.log("error")
                setLoading(false)
              }
            }
            else {
              console.log("error")
              setLoading(false)
            }
          }
        }
        else {
          console.log("error")
          setLoading(false)
        }
      }
    } catch (error) {
      console.error('Error fetching item details:', error);
    }
  }


  useEffect(() => {
    for (var i = 0; i < colors.length; i++) {
      if (colors[i].color === selectedColor && colors[i].image) {
        setItem1({
          ...item1,
          image: colors[i].image,
        });
      }
    }
  }, [selectedColor]);

  useEffect(() => {
    for (var i = 0; i < sizes.length; i++) {
      if (sizes[i].size === selectedSize && sizes[i].image) {
        setItem1({
          ...item1,
          image: sizes[i].image,
        });
      }
    }
  }, [selectedSize]);

  useEffect(() => {
    if (id) {
      fetchItemDetails();
    } else {
      navigate('/items');
    }
  }, [id, navigate]);


  useEffect(() => {
    console.log(item1)
  }, [item1]);



  return (
    <div id="idetail" className={zoomed ? 'zoomed' : ''}>
      {loading ? (
        <Skeleton variant="rectangular" width={'80%'} height={200} style={{ margin: "auto", marginTop: "10px" }} />
      ) : (
        <>
          {!noData ? (
            <Grid container rowSpacing={0.5} columnSpacing={0.5}>
              <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                <Card sx={{ flex: '1', display: 'flex', overflow: 'hidden' }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      style={{
                        width: '60%',
                        margin: "auto",
                        objectFit: 'contain', // to make the image cover the entire container
                        ...zoomStyle
                      }}
                      image={selectedColor != null || selectedSize != null ? `https://api.kolshein.online/storage/${item1.image}` : `https://api.kolshein.online/storage/${item.item_image}`}
                      alt={item.item_name}
                      className="zoom-image"
                      onMouseMove={handleMouseMove}
                    />

                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                <Card sx={{ flex: '1' }}>
                  <CardContent>

                    <p> <b> {item.item_name}</b></p>
                    <p>{item.item_description}</p>
                    <div>
                      <span> Price : $  {item.price_unit}</span>
                    </div>

                    <div>
                      {item.color &&
                        (
                          <div style={{ display: "flex" }}>
                            <span>Color: <Chip
                              icon={<DoneIcon />}
                              label={<span
                                style={{
                                  color: item.color,
                                  fontSize: "40px"
                                }}
                              >
                                ●
                              </span>}
                              style={{ margin: '1px' }}
                            />
                            </span>
                          </div>
                        )
                      }
                      {item1.basic_color ? (
                        <div style={{ display: "flex" }}>
                          {Object.values(item1.basic_color).map((color: any, index: React.Key | null | undefined) => (
                            <Chip
                              key={index}
                              icon={selectedColor === color ? <DoneIcon /> : undefined}
                              label={<span
                                style={{
                                  color: color,
                                  fontSize: "40px"
                                }}
                              >
                                ●
                              </span>}
                              clickable
                              onClick={() => handleColorClick(color)}
                              style={{ margin: '1px' }}
                            />
                          ))}
                          { /* <Link to={`/item_details/${item.color}`}onClick={showColorDetails} style={{ color: 'blue', textDecoration: 'underline' }}>More Color Details</Link> */}
                        </div>
                      ) : (
                        <p>No color options available</p>
                      )}


                      {/* Size section */}


                      {/* Choose size section */}
                      {localStorage.getItem("language") === "1" ? (
                        item1.size && Array.isArray(item1.size) && item1.size.length > 0 ? (
                          <>

                            <p>Choose size:</p>
                            <Stack direction="row" spacing={1}>
                              {item1.size.map((size: any, index: number) => (
                                <Chip
                                  key={index}
                                  label={size}
                                  clickable
                                  color={selectedSize === size ? 'primary' : 'default'}
                                  onClick={() => handleSizeClick(size)}
                                  style={{ margin: '4px' }}
                                />
                              ))}
                            </Stack>
                          </>
                        ) : (
                          <p>No size options available</p>
                        )
                      ) : (
                        item1.size_ar && Array.isArray(item1.size_ar) && item1.size_ar.length > 0 ? (
                          <>
                            <p>Choose size:</p>
                            <Stack direction="row" spacing={1}>
                              {item1.size_ar.map((size: any, index: number) => (
                                <Chip
                                  key={index}
                                  label={size}
                                  clickable
                                  color={selectedSize === size ? 'primary' : 'default'}
                                  onClick={() => handleSizeClick(size)}
                                  style={{ margin: '4px' }}
                                />
                              ))}
                            </Stack>
                          </>
                        ) : (
                          <p>No size options available</p>
                        )
                      )}
                    </div>


                    {
                      item.item_code && (<p>Product Code: {item.item_code}</p>)
                    }

                    {/* <p>Availability: {item.in_stock ? 'In Stock' : 'Out of Stock'}</p> */}



                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                      <p>Price: ${isNaN(TotalPrice) ? 0 : TotalPrice}</p>
                      <div style={{
                        width: "fit-content",
                        borderStyle: "solid",
                        borderRadius: '20px',
                        borderWidth: "1px"
                      }}>
                        <Box
                          display="flex"
                          width={'fit-content'}
                          alignItems="center"
                        >
                          <IconButton onClick={decrement}>
                            <RemoveCircleIcon color="primary" />
                          </IconButton>
                          <Typography variant="h6" style={{ margin: '0 15px' }}>
                            {count}
                          </Typography>
                          <IconButton onClick={increment}>
                            <AddCircleIcon color="primary" />
                          </IconButton>
                        </Box>
                      </div>
                    </Stack>

                  </CardContent>
                  <div style={{
                    width: "fit-content",
                    margin: "auto",
                    marginBottom: "10px"
                  }}>
                    {loadingbtn ? <CircularProgress
                      className='mt-4' /> :
                      <Button variant="contained" onClick={handleSaveItem}>Save</Button>
                    }
                    <p style={{
                      color: colorstatus
                    }}>{status}</p>
                  </div>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
              <div>No Items to show</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default ItemsDetailsEdit;