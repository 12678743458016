import BillApiService from './OrdersApiController';
class BillsController {
    static getBillById(id: string | number) {
        return BillApiService.getBillById(id);
    }
    static getBillOrders(id: string | number) {
        return BillApiService.getBillOrders(id);
    }
    static getUserBills(id: string | number, status: string) {
        return BillApiService.getUserBills(id, status);
    }
}

export default BillsController;